//React
import React from 'react'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import NavSubUsecases from '../../components/nav/navSubUsecases'
import FeatureQuad from '../../components/marketing/featureQuad'
import AlternatingCallout from '../../components/marketing/alternatingCallout'

const useCaseData = [
  {
    title: 'Mitigate health & safety risk for construction sites',
    titleWeight: 'medium',
    titleColor: 'white-1',
    description:
      'The job site has new risks and communication challenges, with mobile, on-the-go workers who need to complete mandatory health screenings in many states',
    descriptionColor: 'white-1',
    image: 'usecaseConstruction',
    iconShow: false,
    // iconColor: "purple",
    lineClass: 'green-1',
    bgcolor: 'blue-1',
    bggradient: 'algae',
  },
  {
    title: 'Automated health & safety screenings for construction employees',
    titleWeight: 'medium',
    description:
      'Send workers a text message before their shift with a health/safety screener, and log that information in case of audit by regulatory agencies',
    image: 'usecaseConstructionSolution',
    iconShow: false,
    iconColor: 'pink',
    lineClass: 'blue-5',
    // videosource: 'sponsorship',
  },
]
const featureData = {
  title: 'Improve safety and reduce regulatory risk',
  titlecolor: 'black-4',
  mode: 'trio',
  bgcolor: 'grayblue-6',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: 'Automate health screenings',
          colbgcolor: 'turquoise-1',
          iconname: 'clipboard',
          description:
            'Auto schedule SMS-based health screeners to workers before the start of their shift, without having to remember or worry',
        },
        {
          title: 'Improve construction site safety',
          colbgcolor: 'green-1',
          iconname: 'shield',
          description:
            "Show your clients and other contractors you're taking worker health precautions seriously, and reduce risk in case of an adverse event",
        },
        {
          title: 'Deliver custom instructions',
          colbgcolor: 'bluegreen-1',
          iconname: 'chatbot',
          description:
            "Notify workers what you'd like them to do, based on their screener responses: report to work, call for more info, or some other action",
        },
      ],
    },
  ],
}
const About = (props) => (
  <Layout>
    <Helmet
      title="Picohealth - Construction - Automated Mobile Health Assessments"
      meta={[
        {
          name: 'description',
          content:
            'Learn about how the Picohealth automated mobile health assessment platform helps reduce risk and drive customer confidence for your construction site and employees',
        },
        {
          name: 'keywords',
          content:
            'Covid, Covid-19, health, health questionnaire, covid questions, return to work, pandemic, pandemic assessment questions, worker health question, workplace health questions, workplace health, company health, company safety',
        },
      ]}
    />

    <NavSubUsecases />

    <div class="container container-page container-about-sub">
      <div class="row">
        <div class="col-xl-10 d-flex flex-column justify-content-center text-center text-lg-left">
          <h1 class="display-2 font-weight-medium bluegreen-1">
            Automate health &amp; safety screenings on job sites
          </h1>
          <p class="display-4">
            Reduce your job site risk by using automated SMS health screening
            for employees &amp; contractors &mdash; and maintain an audit log
            for regulatory agencies
          </p>
        </div>
      </div>
    </div>

    {/* <DividerMid
      class="purple-1"
      bottomclass="white-4"
      flip={false}
      lineclass="blue-1"
      lineoffset={4}
      lineflip={true}
      lineclass2="red-1"
      lineoffset2={6}
      lineflip2={false}
      // lineclass3="yellow-1"
      // lineoffset3={10}
      // lineflip3={false}
    /> */}

    <AlternatingCallout featureData={useCaseData} />
    {/* 
    <BenefitsBoomerang
      color="white-1"
      bgcolor="blue-1"
      gradientOverlay={true}
      containerTitle="Benefits for brands"
      data={[
        {
          title: "Title 1",
          body:
            "Multiply the reach of social campaigns through compelling, mass-personalized videos.",
        },
        {
          title: "Title 2",
          body:
            "Measure the impact of personalized video with social landing pages and analytics",
        },
        {
          title: "Title 3",
          body:
            "Use a variety of creative treatments to engage users, and ensure brand-safe content with moderation",
        },
      ]}
    /> */}

    {/* SELECTED FEATURES */}
    <FeatureQuad featureData={featureData} />
    {/* END SELECTED FEATURES */}
  </Layout>
)

export default About
